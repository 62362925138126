import React from "react";
import { Box, makeStyles, Paper } from "@material-ui/core";

const useCategoryStyle = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    ...theme.typography.caption,
    color: theme.palette.text.secondary,
    "& h6": {
      ...theme.typography.body2,
      color: theme.palette.text.primary,
      lineHeight: 1,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    "& ul": {
      listStylePosition: "outside",
      paddingLeft: theme.spacing(2),
      margin: "0",
    },
  },
}));

export const CfsCard: React.FC = ({ children }) => {
  const classNames = useCategoryStyle();

  return (
    <Paper className={classNames.root}>
      <Box margin={2}>{children}</Box>
    </Paper>
  );
};
