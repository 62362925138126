import React from "react";
import { makeText } from "helpers/i18n";
import { ExternalLink } from "helpers/Link";

// ----------------------------------------------------------------
//     テキスト
// ----------------------------------------------------------------

const useText = makeText({
  ja: {
    s2015:
      "https://docs.google.com/spreadsheets/d/186-gpTHPvGwtQTFqvuSs10Tz3ca49gKNZ7uoRSi2HnE/edit#gid=0",
    s2016:
      "https://docs.google.com/spreadsheets/d/1XEkt0MWC_vBI2BHBH0qwHxM7QdqajP3zu1lDnCytJoU/edit#gid=0",
    s2017:
      "https://docs.google.com/spreadsheets/d/1k4bpUjpAyiJfTCJCFc9y48FYQAujZfCA_fxsmN2M4LI/edit#gid=0",
    s2018: "https://droidkaigi.jp/2018/proposals/",
    s2019: "https://droidkaigi.jp/2019/proposals/",
  },
  en: {
    s2015:
      "https://docs.google.com/spreadsheets/d/186-gpTHPvGwtQTFqvuSs10Tz3ca49gKNZ7uoRSi2HnE/edit#gid=0",
    s2016:
      "https://docs.google.com/spreadsheets/d/1XEkt0MWC_vBI2BHBH0qwHxM7QdqajP3zu1lDnCytJoU/edit#gid=0",
    s2017:
      "https://docs.google.com/spreadsheets/d/1k4bpUjpAyiJfTCJCFc9y48FYQAujZfCA_fxsmN2M4LI/edit#gid=0",
    s2018: "https://droidkaigi.jp/2018/en/proposals/",
    s2019: "https://droidkaigi.jp/2019/en/proposals/",
  },
});

// ----------------------------------------------------------------
//     全体構成
// ----------------------------------------------------------------

const PastSubmissions: React.FC = () => {
  const text = useText();
  return (
    <React.Fragment>
      <ExternalLink href={text.s2015}>2015</ExternalLink>
      &nbsp;&nbsp;
      <ExternalLink href={text.s2016}>2016</ExternalLink>
      &nbsp;&nbsp;
      <ExternalLink href={text.s2017}>2017</ExternalLink>
      &nbsp;&nbsp;
      <ExternalLink href={text.s2018}>2018</ExternalLink>
      &nbsp;&nbsp;
      <ExternalLink href={text.s2019}>2019</ExternalLink>
    </React.Fragment>
  );
};

export default PastSubmissions;
