import React from "react";
import { Box, Grid, Hidden } from "@material-ui/core";
import { makeText } from "helpers/i18n";
import { CfsCard } from "./cfscard";
import kotlinPng from "../../../images/cfs-category/kotlin.png";
import securityPng from "../../../images/cfs-category/security.png";
import uiuxPng from "../../../images/cfs-category/uiux.png";
import architecturePng from "../../../images/cfs-category/architecture.png";
import hardwarePng from "../../../images/cfs-category/hardware.png";
import androidPlatformPng from "../../../images/cfs-category/android-platform.png";
import testingPng from "../../../images/cfs-category/testing.png";
import processPng from "../../../images/cfs-category/process.png";
import jetpackPng from "../../../images/cfs-category/jetpack.png";
import toolsPng from "../../../images/cfs-category/tools.png";
import crossPng from "../../../images/cfs-category/cross.png";
import otherPng from "../../../images/cfs-category/other.png";

// ----------------------------------------------------------------
//     テキスト
// ----------------------------------------------------------------

const useText = makeText({
  ja: {
    categories: [
      {
        title: "Kotlin",
        detail: (
          <ul>
            <li>FrameworksとLibraries</li>
            <li>Kotlin Coroutines, Kotlin Coroutines Flow</li>
            <li>etc…</li>
          </ul>
        ),
      },
      {
        title: "セキュリティ",
        detail: (
          <ul>
            <li>難読化, ProGuard</li>
            <li>ユーザー認証</li>
            <li>etc...</li>
          </ul>
        ),
      },
      {
        title: "UI・UX・デザイン",
        detail: (
          <ul>
            <li>Material Design</li>
            <li>Material Theming</li>
            <li>MotionLayout</li>
            <li>Lottie, AnimatedVectorDrawable</li>
            <li>etc...</li>
          </ul>
        ),
      },
      {
        title: "アプリアーキテクチャ",
        detail: (
          <ul>
            <li>Clean Architecture</li>
            <li>MVVM, MVC, MVP, MVI, Flux etc.</li>
            <li>DI</li>
            <li>Multi-module, Instant Apps</li>
            <li>etc...</li>
          </ul>
        ),
      },
      {
        title: "ハードウェア",
        detail: (
          <ul>
            <li>カメラ, センサー</li>
            <li>動画、画像、音声技術 など</li>
            <li>GPU (OpenGL/Vulkan)</li>
            <li>GPS, タッチセンサー</li>
            <li>etc...</li>
          </ul>
        ),
      },
      {
        title: "Androidプラットフォーム",
        detail: (
          <ul>
            <li>TV, Watch, Home, Nest Hub</li>
            <li>Auto</li>
            <li>ChromeOS</li>
            <li>etc...</li>
          </ul>
        ),
      },
      {
        title: "保守・運用・テスト",
        detail: (
          <ul>
            <li>CI, CD</li>
            <li>テスト</li>
            <li>Lint</li>
            <li>リリースフロー</li>
            <li>etc...</li>
          </ul>
        ),
      },
      {
        title: "開発体制",
        detail: (
          <ul>
            <li>チーム体制</li>
            <li>コードレビュー</li>
            <li>リーダーシップ</li>
            <li>etc…</li>
          </ul>
        ),
      },
      {
        title: "Android FrameworkとJetpack",
        detail: (
          <ul>
            <li>Android内部の仕組み、Notification、MediaCodec</li>
            <li>AAC (Room, Navigation, etc...)</li>
            <li>OSバージョンごとの実装</li>
            <li>etc…</li>
          </ul>
        ),
      },
      {
        title: "開発ツール",
        detail: (
          <ul>
            <li>Android Studio</li>
            <li>ビルドシステム（高速化、効率化等）</li>
            <li>etc…</li>
          </ul>
        ),
      },
      {
        title: "クロスプラットフォーム",
        detail: (
          <ul>
            <li>React Native, Flutter, Xamarin</li>
            <li>Golang, Delphi</li>
            <li>etc…</li>
          </ul>
        ),
      },
      {
        title: "その他",
        detail: (
          <ul>
            <li>上記に該当しないもの</li>
          </ul>
        ),
      },
    ],
  },
  en: {
    categories: [
      {
        title: "Kotlin",
        detail: (
          <ul>
            <li>Frameworks and Libraries</li>
            <li>Kotlin Coroutines, Kotlin Coroutines Flow</li>
            <li>etc…</li>
          </ul>
        ),
      },
      {
        title: "Security",
        detail: (
          <ul>
            <li>Obfuscation, ProGuard</li>
            <li>User Authentication</li>
            <li>etc...</li>
          </ul>
        ),
      },
      {
        title: "UI・UX・Design",
        detail: (
          <ul>
            <li>Material Design</li>
            <li>Material Theming</li>
            <li>MotionLayout</li>
            <li>Lottie, AnimatedVectorDrawable</li>
            <li>etc...</li>
          </ul>
        ),
      },
      {
        title: "Designing App Architecture",
        detail: (
          <ul>
            <li>Clean Architecture</li>
            <li>MVVM, MVC, MVP, MVI, Flux etc.</li>
            <li>DI</li>
            <li>Multi-module, Instant Apps</li>
            <li>etc...</li>
          </ul>
        ),
      },
      {
        title: "Hardware",
        detail: (
          <ul>
            <li>Camera, Sensors</li>
            <li>Video, Image, Audio techniques</li>
            <li>GPU (OpenGL/Vulkan)</li>
            <li>GPS, Touch sensors</li>
            <li>etc...</li>
          </ul>
        ),
      },
      {
        title: "Android Platforms",
        detail: (
          <ul>
            <li>TV, Watch, Home, Nest Hub</li>
            <li>Auto</li>
            <li>ChromeOS</li>
            <li>etc...</li>
          </ul>
        ),
      },
      {
        title: "Maintenance Operations and Testing",
        detail: (
          <ul>
            <li>CI, CD</li>
            <li>Testing</li>
            <li>Lint</li>
            <li>Release flow</li>
            <li>etc...</li>
          </ul>
        ),
      },
      {
        title: "Development processes",
        detail: (
          <ul>
            <li>Team Building</li>
            <li>Code Review</li>
            <li>Leadership</li>
            <li>etc…</li>
          </ul>
        ),
      },
      {
        title: "Android Framework and Jetpack",
        detail: (
          <ul>
            <li>Inside Android, Notification, MediaCodec</li>
            <li>AAC (Room, Navigation, etc...)</li>
            <li>OS Version Differences</li>
            <li>etc…</li>
          </ul>
        ),
      },
      {
        title: "Productivity and Tools",
        detail: (
          <ul>
            <li>Android Studio</li>
            <li>Build Systems(Speed, Efficiency etc.)</li>
            <li>etc…</li>
          </ul>
        ),
      },
      {
        title: "Cross-platform Development",
        detail: (
          <ul>
            <li>React Native, Flutter, Xamarin</li>
            <li>Golang, Delphi</li>
            <li>etc…</li>
          </ul>
        ),
      },
      {
        title: "Other",
        detail: (
          <ul>
            <li>Anything that doesn&apos;t fit into the categories above</li>
          </ul>
        ),
      },
    ],
  },
});

// ----------------------------------------------------------------
//     全体構成
// ----------------------------------------------------------------

const images = [
  kotlinPng,
  securityPng,
  uiuxPng,
  architecturePng,
  hardwarePng,
  androidPlatformPng,
  testingPng,
  processPng,
  jetpackPng,
  toolsPng,
  crossPng,
  otherPng,
];

const Category: React.FC<{
  title: string;
  detail: React.ReactElement;
  image: string;
}> = ({ title, detail, image }) => (
  <CfsCard>
    <div style={{ display: "flex" }}>
      <div style={{ flex: "0 0 76px" }}>
        <img width="60" height="60" src={image} />
      </div>
      <div style={{ flex: 1 }}>
        <h6 style={{ marginTop: 0 }}>{title}</h6>
        {detail}
      </div>
    </div>
  </CfsCard>
);

const Categories: React.FC = () => {
  const text = useText();
  const categories = text.categories.map((c, i) => ({
    ...c,
    image: images[i],
  }));
  const columns = [
    categories.filter((_, i) => i % 3 === 0 && i !== 12),
    categories.filter((_, i) => i % 3 === 1),
    categories.filter((_, i) => i % 3 === 2 || i === 12),
  ];
  return (
    <React.Fragment>
      <Hidden xsDown>
        <Grid container spacing={2}>
          {columns.map((cs, i) => {
            return (
              <Grid item key={i} xs={4}>
                {cs.map((c) => {
                  return (
                    <Box key={c.title} marginTop={2}>
                      <Category {...c} />
                    </Box>
                  );
                })}
              </Grid>
            );
          })}
        </Grid>
      </Hidden>
      <Hidden smUp>
        {categories.map((c) => {
          return (
            <Box key={c.title} marginTop={2}>
              <Category {...c} />
            </Box>
          );
        })}
      </Hidden>
    </React.Fragment>
  );
};

export default Categories;
